import React, { FC } from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import {
  createDescriptionTags,
  createImageTags,
  createOtherTags,
  createTitleTags,
  createUriTags,
} from "./tags"


interface SeoProps {
  description: string,
  lang?: string,
  image?: string,
  meta?: object[]
  title: string
  type?: string
  reverseTitle?: boolean
  uri?: string
}
const SEO  : FC<SeoProps>= ({
  description,
  lang = 'en',
  meta = [],
  title,
  image = null,
  type = 'website',
  uri = null,
  reverseTitle = false,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `
  )
  const metaDescription = description || site.siteMetadata.description
  const metaTitle = reverseTitle
    ? `${site.siteMetadata.title} | ${title}`
    : `${title} | ${site.siteMetadata.title}`
  const metaImage = image ? `${site.siteMetadata.siteUrl}${image}` : null
  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={metaTitle}
      meta={[
        ...createDescriptionTags(metaDescription),
        ...createTitleTags(metaTitle),
        ...createImageTags(metaImage),
        ...createOtherTags(type),
        ...createUriTags(uri || site.siteMetadata.siteUrl),
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  type: "website",
  image: null,
  reverseTitle: false,
}
export default SEO
