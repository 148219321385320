export const createImageTags = (image) => {
  let ret = []
  if (image) {
    ret = [{
      property: `og:image`,
      content: image,
    },
    {
      property: `twitter:image`,
      content: image,
    }]
  }
  return ret
}

export const createTitleTags = (title) => ([
  {
    property: `og:title`,
    content: title,
  },
  {
    property: `twitter:title`,
    content: title,
  },
])

export const createDescriptionTags = (metaDescription) => ([
  {
    property: `og:description`,
    content: metaDescription,
  },
  {
    name: `description`,
    content: metaDescription,
  },
  {
    property: `twitter:description`,
    content: metaDescription,
  },
])

export const createOtherTags = (type) => ([
  {
    property: `og:locale`,
    content: 'en_AU',
  },
  {
    property: `twitter:card`,
    content: 'summary_large_content',
  },
  {
    property: `og:type`,
    content: type,
  },
])

export const createUriTags = (url) => ([
  {
    property: `og:url`,
    content: url,
  },
])